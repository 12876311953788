import { Component, Prop, Vue } from 'vue-property-decorator';
import WithRender from './password-recovery-selector.html';
import { UIComponent } from 'ui-controls-core/ui-component';
import { LoginRouteNames } from 'membership/auth/login/routes/login-routes-namings';
import { PasswordRecoveryRouteNames } from 'membership/auth/password-recovery/routes/password-recovery-route-namings';

require('./password-recovery-selector.scss');

@WithRender
@Component
export class PasswordRecoverySelector extends UIComponent {
	public goToEmailPasswordRecovery() {
		this.$router.push({
			name: PasswordRecoveryRouteNames.EmailPasswordRecovery,
			query: this.$route.query,
			params: this.$route.params
		});
	}

	public goToSecretQuestionRecovery() {
		this.$router.push({
			name: PasswordRecoveryRouteNames.SecretQuestionPasswordRecovery,
			query: this.$route.query,
			params: this.$route.params
		});
	}

	public cancel() {
		this.$router.push({ name: LoginRouteNames.Login, query: this.$route.query, params: this.$route.params });
	}
}
