<pnz-form id="enter-verification-code-form">
	<pnz-form-row>
		<div class="col-12 text-center">
			<p>
				<span>{{ 'messageVerificationCodeSent' | localize | format(this.model.email) }}</span>
				<br />
				<span>{{ 'verificationCodePrompt' | localize }}</span>
			</p>
		</div>
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group-with-error-block col-sm="12" field="verificationCode" :label="'verificationCode' | localize" :auto-focus="true" />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group-with-error-block col-sm="12" field="captcha" :label="'captchaCode' | localize" type="captcha" />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-empty-row />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group :with-top-margin="false">
			<pnz-button type="submit" :is-loading="isLoading">{{ 'next' | localize }}</pnz-button>
		</pnz-form-group>
	</pnz-form-row>
	<pnz-form-row>
		<pnz-empty-row />
	</pnz-form-row>
</pnz-form>
