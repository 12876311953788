import { Component, Prop, Vue } from 'vue-property-decorator';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';
import WithRender from './secret-question-password-recovery-form.html';
import { container } from 'utils/ioc/container';
import { MembershipNamings } from 'membership/membership-namings';
import { PasswordRecoveryRouteNames } from 'membership/auth/password-recovery/routes/password-recovery-route-namings';

class EmailModel {
	public email!: string;
}

@WithRender
@Component
export class SecretQuestionPasswordRecoveryForm extends BootstrapFormContainer<EmailModel> {
	public get rules() {
		return {
			email: ['required']
		};
	}
	public membershipService: IMembershipService;

	public model = new EmailModel();

	constructor() {
		super();
		this.membershipService = container.get<IMembershipService>(MembershipNamings.membershipServiceName);
	}

	public cancel(): void {
		this.$router.push({
			name: PasswordRecoveryRouteNames.SelectPasswordRecovery,
			query: this.$route.query,
			params: this.$route.params
		});
	}

	public processSubmit(): Promise<any> {
		return this.membershipService.getSecretQuestion(this.model);
	}

	public success(param: { result: IResponsePayload<ISecretQuestionData> }) {
		const params: ISecretQuestionPasswordRecoveryAnswerStateParams = Object.assign(this.$route.params, {
			email: this.model.email,
			secretQuestion: param.result.data.secretQuestion,
			recoveryToken: param.result.data.recoveryToken
		});
		this.$router.push({
			name: PasswordRecoveryRouteNames.SecretQuestionPasswordRecoveryAnswer,
			params: params as any,
			query: this.$route.query
		});
	}
}
