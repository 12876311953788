<div id="password-recovery-selector" class="container-fluid">
	<pnz-empty-row />
	<h5 class="text-center">{{ 'choosePasswordRecoveryWay' | localize }}</h5>
	<div class="row">
		<div class="col-md-6 col-12 text-center">
			<a href="javascript:void(0);" v-on:click.prevent="goToEmailPasswordRecovery();" class="pnz-password-recovery-link btn pnz-btn pnz-btn-link js-password-recovery-by-email pnz-link">
        <div class="text-center pnz-recovery-icon"><i class="fa fa-envelope" aria-hidden="true"></i></div>
        <span class="pnz-recovery-method">{{ 'emailPasswordRecoveryWay' | localize }}</span>
      </a>
		</div>
		<div class="col-md-6 col-12 text-center">
			<a href="javascript:void(0);" v-on:click.prevent="goToSecretQuestionRecovery();" class="pnz-password-recovery-link btn pnz-btn pnz-btn-link js-password-recovery-by-secret-question pnz-link">
        <div class="text-center pnz-recovery-icon"><i class="fa fa-lock" aria-hidden="true"></i></div>
        <span class="pnz-recovery-method">{{ 'sqpasswordRecoveryWay' | localize }}</span>
      </a>
		</div>
	</div>
	<pnz-empty-row />
	<pnz-empty-row />
	<div class="col-12">
		<button type="button" class="btn btn-default pnz-btn btn-block js-cancel-button" v-on:click="cancel()">{{ 'cancel' | localize }}</button>
	</div>
</div>
