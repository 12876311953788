import { Component, Prop, Vue } from 'vue-property-decorator';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';
import WithRender from './secret-question-password-recovery-answer-form.html';
import { container } from 'utils/ioc/container';
import { MembershipNamings } from 'membership/membership-namings';
import { PasswordRecoveryRouteNames } from 'membership/auth/password-recovery/routes/password-recovery-route-namings';

class SecretQuestionPasswordRecoveryAnswerModel {
	public email!: string;
	public recoveryToken!: string;
	public answer!: string;
}

@WithRender
@Component
export class SecretQuestionPasswordRecoveryAnswerForm extends BootstrapFormContainer<
	SecretQuestionPasswordRecoveryAnswerModel
> {
	public membershipService: IMembershipService;

	public model = new SecretQuestionPasswordRecoveryAnswerModel();

	public get rules() {
		return {
			email: ['required'],
			answer: ['required'],
			recoveryToken: []
		};
	}

	public secretQuestion!: string;

	constructor() {
		super();
		this.membershipService = container.get<IMembershipService>(MembershipNamings.membershipServiceName);
	}

	public created() {
		const params = (this.$route.params as any) as ISecretQuestionPasswordRecoveryAnswerStateParams;
		this.secretQuestion = params.secretQuestion;
		this.model.email = params.email;
		this.model.recoveryToken = params.recoveryToken;
	}

	public cancel(): void {
		this.$router.push({
			name: PasswordRecoveryRouteNames.SelectPasswordRecovery,
			query: this.$route.query,
			params: this.$route.params
		});
	}

	public processSubmit(): Promise<any> {
		return this.membershipService.getSecretQuestion(this.model);
	}

	public success(param: { result: IEmptyResponseResponse }) {
		const params: IVerificationCodeStateParams = Object.assign(this.$route.params, {
			email: this.model.email
		});
		this.$router.push({
			name: PasswordRecoveryRouteNames.EnterVerificationCode,
			params: params as any,
			query: this.$route.query
		});
	}
}
