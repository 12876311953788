import { Component, Prop, Vue } from 'vue-property-decorator';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';
import WithRender from './email-password-recovery-form.html';
import { MembershipNamings } from 'membership/membership-namings';
import { PasswordRecoveryRouteNames } from 'membership/auth/password-recovery/routes/password-recovery-route-namings';

class EmailModel {
	public email!: string;
}

@WithRender
@Component
export class EmailPasswordRecoveryForm extends BootstrapFormContainer<EmailModel> {
	public membershipService: IMembershipService;

	public model = new EmailModel();

	public get rules() {
		return {
			email: ['required']
		};
	}

	constructor() {
		super();
		this.membershipService = this.get<IMembershipService>(MembershipNamings.membershipServiceName);
	}

	public cancel(): void {
		this.$router.push({
			name: PasswordRecoveryRouteNames.SelectPasswordRecovery,
			query: this.$route.query,
			params: this.$route.params
		});
	}

	public back(): void {
		this.$router.push({
			name: PasswordRecoveryRouteNames.SelectPasswordRecovery,
			query: this.$route.query,
			params: this.$route.params
		});
	}

	public processSubmit(): Promise<any> {
		return this.membershipService.recoverPassword(this.model);
		//return Promise.resolve();
	}

	public success(param: { result: IEmptyResponseResponse }) {
		const params: IVerificationCodeStateParams = Object.assign(this.$route.params, {
			email: this.model.email
		});
		this.$router.push({
			name: PasswordRecoveryRouteNames.EnterVerificationCode,
			params: params as any,
			query: this.$route.query
		});
	}
}
