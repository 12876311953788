import { Component, Prop, Vue } from 'vue-property-decorator';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';
import WithRender from './enter-verification-code-form.html';
import { container } from 'utils/ioc/container';
import { MembershipNamings } from 'membership/membership-namings';
import { PasswordRecoveryRouteNames } from 'membership/auth/password-recovery/routes/password-recovery-route-namings';

class VerificationCodeModel {
	public email!: string;
	public verificationCode!: string;
}

@WithRender
@Component
export class EnterVerificationCodeForm extends BootstrapFormContainer {
	public membershipService: IMembershipService;

	public model = new VerificationCodeModel();

	public get rules() {
		return {
			email: ['required'],
			verificationCode: ['required']
		};
	}

	constructor() {
		super();
		this.membershipService = container.get<IMembershipService>(MembershipNamings.membershipServiceName);
	}

	public created() {
		this.model.email = ((this.$route.params as any) as IVerificationCodeStateParams).email;
	}

	public processSubmit(): Promise<any> {
		return this.membershipService.recoverPasswordByCode(this.model);
	}

	public success(param: { result: IResponsePayload<IRecoverPasswordData> }) {
		const params: ISetPasswordStateParams = Object.assign(this.$route.params, {
			token: param.result.data.token
		});
		this.$router.push({
			name: PasswordRecoveryRouteNames.SetNewPassword,
			params: params as any,
			query: this.$route.query
		});
	}
}
