<pnz-form id="email-password-recovery-form">
	<pnz-form-row>
		<pnz-form-group-with-error-block col-sm="12" field="answer" :label="secretQuestion" :auto-focus="true" />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-empty-row />
	</pnz-form-row>
	<pnz-form-row>
		<pnz-form-group :with-top-margin="false">
			<pnz-button type="submit" :is-loading="isLoading">{{ 'recover' | localize }}</pnz-button>
		</pnz-form-group>
	</pnz-form-row>
	<pnz-form-row>
		<pnz-empty-row />
	</pnz-form-row>
</pnz-form>
