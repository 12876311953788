import { Component, Prop, Vue } from 'vue-property-decorator';
import { BootstrapFormContainer } from 'ui-controls/forms/bootstrap-form-container';
import WithRender from './set-new-password-form.html';
import { container } from 'utils/ioc/container';
import { MembershipNamings } from 'membership/membership-namings';

class SetNewPasswordModel {
	public password!: string;
	public passwordConfirmation!: string;
	public token!: string;
	public redirectUrl!: string;
	public preserveGameSession!: boolean;
}

@WithRender
@Component
export class SetNewPasswordForm extends BootstrapFormContainer {
	public membershipService: IMembershipService;

	@Prop({ type: String })
	public redirectUrl!: string;

	public model = new SetNewPasswordModel();

	public get rules() {
		return {
			password: ['required', 'min:6'],
			passwordConfirmation: ['required', 'min:6', 'pnz-password-confirmation:' + this.model.password]
		};
	}

	constructor() {
		super();
		this.membershipService = container.get<IMembershipService>(MembershipNamings.membershipServiceName);
	}

	public created() {
		const params = (this.$route.params as any) as ISetPasswordStateParams;
		this.model.token = params.token;
		this.model.preserveGameSession = params.preserveGameSession || false;
		this.model.redirectUrl = this.redirectUrl || (this.$route.query.redirectUrl as string);
	}

	public processSubmit(): Promise<any> {
		return this.membershipService.setNewPassword(this.model);
	}
}
